.ui.segment.predef-answers * {
	text-transform: initial;
}
.ui.segment.predef-answers > .page-header {
	margin-top: 0;
	margin-bottom: 1rem;
}
.ui.segment.predef-answers .ui.button.placeholders {
	width: 1em;
	height: 1em;
	margin-left: 1rem;
	padding: 0;
}
.ui.segment.predef-answers .ui.button.placeholders > i.info.icon {
	transform: scale(0.5);
}
.ui.segment.predef-answers > .ui.container.vertically.divided.grid > .column {
	flex: 2;
	padding: 0;
	margin: 1rem 0;
}
.ui.segment.predef-answers > .ui.container.vertically.divided.grid > .column:first-child {
	flex: 1;
}
.ui.segment.predef-answers > .ui.container.vertically.divided.grid > .column:nth-child(2) {
	flex: 0;
	margin: 1rem;
}
.ui.segment.predef-answers > .ui.container.vertically.divided.grid > .column:nth-child(2) > .divider {
	margin: 0;
}
.ui.segment.predef-answers .ui.segment.menu,
.ui.segment.predef-answers .ui.segment.menu .ui.accordion > .content,
.ui.segment.predef-answers .ui.segment.answer-list {
	padding: 0;
}
.ui.segment.predef-answers .ui.segment.answer-list {
	height: 100%;
}
.ui.segment.predef-answers .ui.segment.menu .list {
	margin: 0;
}
.ui.segment.predef-answers .ui.segment.menu .list > .item {
	background-color: #f3f4f5;
	padding: 0.2rem 0;
}
.ui.segment.predef-answers .ui.segment.menu .list > .item:hover {
	cursor: pointer;
	background-color: #e3e6e8;
}
.ui.segment.predef-answers .ui.segment.menu .list.sub-menu > .item .header,
.ui.segment.predef-answers .ui.segment.menu .list > .item .active-icon {
	display: inline-block;
	margin: 0.6rem;
}
.ui.segment.predef-answers .ui.segment.menu .list.sub-menu > .item .header {
	padding-left: 2.4rem;
	font-weight: normal;
}
.ui.segment.predef-answers .ui.segment.menu .list > .item.active {
	background-color: #fbd35b;
}
.ui.segment.predef-answers .ui.segment.menu .list.sub-menu > .item.active .header {
	font-weight: bold;
}
.ui.segment.predef-answers .ui.segment.menu .list > .item .active-icon {
	float: right;
	clear: right;
	padding-right: 1rem;
}
.ui.segment.predef-answers .ui.segment.answer-list .ui.card {
	width: 100%;
}
.ui.segment.predef-answers .ui.segment.answer-list .ui.card > .content {
	display: flex;
	flex-direction: row;
}
.ui.segment.predef-answers .ui.segment.answer-list .ui.card > .content > .answer {
	flex: 1;
}
.ui.segment.predef-answers .ui.segment.answer-list .action-button-list {
	text-align: right;
	padding-bottom: 1em;
}
.ui.segment.predef-answers .ui.segment.answer-list .action-button-list > .button {
	margin: 0;
	margin-left: 0.6rem;
}
.ui.segment.predef-answers .ui.segment.no-answers {
	height: 100%;
	flex-direction: row;
}
.ui.segment.predef-answers .ui.segment.no-answers > .header {
	margin: auto;
}

.ui.modal.predef-answers-action > .content,
.ui.modal.predef-answers-action .form > .field {
	margin: 0;
}
.ui.modal.predef-answers-action .form > .field > textarea {
	border-bottom-right-radius: 0;
}
.ui.modal.predef-answers-action .form > .ui.label {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.ui.modal.predef-answers-action .form {
	text-align: right;
}
#root > .ui.fluid.container {
	height: 100vh;
	margin: 0!important;
}
.ui.container > .ui.menu.page-header {
	border-radius: 0;
	margin: 0;
	height: 4.5em;
}
.ui.container > .ui.menu.page-header .item {
	padding: 1em;
}
.ui.container > .ui.menu.page-header .item.brand {
	font-size: 1.2em;
}
.ui.container > .ui.menu.page-header .item.control {
	padding: 0;
}
.ui.container > .ui.menu.page-header .item.control > .dropdown {
	padding: 1em;
}
.ui.container > .page-header > .ui.dropdown.control .menu {
	margin: 0.5em;
}

.ui.container > .page-content {
	height: calc(100% - 4.5em);
	overflow-y: scroll;
}
.ui.container > .page-content > .ui.segment {
	max-width: 1200px;
	margin: 1.5em auto;
}

.page-content {
	margin-top: 80px; /* adapt to the current page spacing */
}
.operation.page-content *,
.operation-action-modal *,
.ui.popup *,
.ui.modal *,
.operation-details * {
	text-transform: initial;
}



.ui.modals > .ui.modal.operation-action-modal {
	margin: unset;
}
.ui.modals > .ui.modal.operation-action-modal.smaller {
	width: 50%;
}



.ui.container.page-content > .ui.segment {
	margin: 1em auto;
}

.ui.segment.operation {
	padding: 0;
}

.ui.segment.operation .segment-tab {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	margin-bottom: 0;
}

.ui.segment.operation .ui.container.active.tab > .ui.segment {
	margin: 1em 0;
}
.ui.basic.ui.segment.operation-role-select,
.ui.segment.operation-data-table,
.ui.segment.operation-info,
.ui.segment.operation-details {
	padding: 0;
}

.ui.basic.ui.segment.operation-role-select > .ui.dropdown > * {
	margin-right: 0.5em;
}
.ui.basic.ui.segment.operation-role-select > .ui.dropdown > .icon {
	float: left;
}

.ui.segment.operation-info {
	margin: 0;
	text-align: right;
}

.ui.form.operation-search-form .fields:first-child > .field:first-child {
	flex: 1;
}
.ui.form.operation-search-form .fields:first-child > .author-filter,
.ui.form.operation-search-form .fields:first-child > .operation-rating-range-select {
	flex: 0.5;
}
.ui.form.operation-search-form .field > .ui.button {
	height: 100%;
	margin: 0;
	margin-right: 0.05em;
}

.ui.form .field.operation-cases-status-filter {
	flex: 0.5 1;
}
.ui.form .field.operation-cases-status-filter.agent {
	flex: 0.75 1;
}
.ui.form .field.operation-cases-user-filter,
.ui.form .field.operation-rating-range-select {
	flex: 0.25 1;
}
.ui.modal.operation-action-modal .field.operation-cases-user-filter,
.ui.modal.operation-action-modal .field.operation-cases-user-filter > div > i.icon {
	margin-left: 0.6rem;
}
.ui.modal.operation-action-modal .field.operation-cases-user-filter {
	width: max-content;
	min-width: 16em;
	display: inline-block;
}
.ui.form .field.operation-cases-status-filter > div > i.icon,
.ui.form .field.operation-cases-user-filter > div > i.icon,
.ui.modal.operation-action-modal .field.operation-cases-user-filter > div > i.icon,
.criteria-field > .field i.filter.icon {
	cursor: default;
	position: absolute;
	line-height: 1;
	text-align: center;
	right: 0;
	margin: 0;
	height: 100%;
	width: 2.67142857em;
	opacity: .5;
	border-radius: 0 .28571429rem .28571429rem 0;
	-webkit-transition: opacity .3s ease;
	transition: opacity .3s ease;
}
.ui.form .field.operation-cases-status-filter > div > i.icon,	/* TODO: Fix non-responsive icon spacing */
.ui.modal.operation-action-modal .field.operation-cases-user-filter > div > i.icon,
.criteria-field > .field i.filter.icon {
	display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
}
.ui.form .field.operation-cases-status-filter > div.active > i.icon,
.ui.form .field.operation-cases-user-filter > div.active > i.icon,
.ui.modal.operation-action-modal .field.operation-cases-user-filter > div.active > i.icon,
.criteria-field > .field i.filter.icon {
	opacity: 1;
}
.ui.form .field.operation-cases-user-filter > div > i.icon.clear {
	z-index: 3;
	cursor: pointer;
}
.ui.modal.operation-action-modal .field.operation-cases-user-filter .visible.menu.transition > .item > span {
	line-height: 1.4;
}

.ui.dropdown.operation-data-dropdown .menu > .item {
	padding-left: 2.4em!important;
}

body > .ui.modals.dimmer {
	background-color: #000C;
	z-index: 1040;
}
.ui.modal {
	top: revert;
    left: revert;
	width: 75%;
	height: auto;
	overflow: revert;
}
.ui.modal > .header {
	height: 3.2em;
}
.ui.modal > .content {
	max-height: calc(100vh - 3.5rem - 7.7em);
	overflow-y: scroll;
}
.ui.modal.small > .content {
	overflow-y: revert;
}
.ui.modal > .actions {
	height: 4.5em;
}
.ui.modal > .actions > .ui.button.padding-placeholder {
	width: 0;
	visibility: hidden;
}
.ui.selectable.table tr {
	cursor: pointer;
}
.ui.segment.operation .ui.container.active.tab > .ui.segment.operation-data-table {
	margin-top: 0;
}
.ui.segment.operation-data-table td {
	vertical-align: middle;
}
.ui.segment.operation-data-table td > .ui.label:first-child {
	margin-right: 0.75em;
}
.ui.segment.operation-data-table td > .one-liner {
	white-space: nowrap;
}
.ui.segment.operation-data-table td > .long-text {
	overflow: hidden;
	max-width: 12em;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.ui.list.tree-view .ui.button.tree-view-fold-btn {
	padding: 0;
    background: #0000;
}
.ui.list.tree-view .tree-view.item {
	border-left: 0.15em solid;
	padding: 0;
    padding-left: 0.5em;
    margin-left: 0.525em;
}
.ui.list .tree-view.item.hidden {
	display: none;
}

.ui.button.date-picker-button {
	padding: 0 1.5em;
}
.ui.modal.date-picker-modal {
	width: unset;
}

.ui.buttons.operation-platform-buttons .ui.button.active.play_store {
	background-color: #689f38;
}
.ui.buttons.operation-platform-buttons .ui.button.active.app_store {
	background-color: #0071e3;
}

.ui.popup > ul {
	margin-bottom: 0;
}
.ui.popup ul > li {
	margin-top: 0.4em;
}

.ui.form .field.operation-date-label {
	padding-right: 0.1em;
}
.ui.form .field.operation-date-label .label {
	height: 100%;
	background-color: #d1e2f0;
	padding-top: 0;
	padding-bottom: 0;
	text-align: right;
	line-height: 1.6;
}

.ui.segment.operation-results-info {
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
}
.ui.segment.operation-results-info > button {
	transform: translateY(-0.35em);
}

div.sample {
	width: 1em;
	height: 1em;
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.2em;
}
table.ui.table tr.sla-green,
div.sample.sla-green {
	background-color: #0f02;
}
table.ui.table tr.sla-yellow,
div.sample.sla-yellow {
	background-color: #fd03;
}
table.ui.table tr.sla-red,
div.sample.sla-red {
	background-color: #f004;
}
table.ui.table tr.clickable:hover {
	filter: brightness(0.5);
	cursor: pointer;
}
table.ui.table tr.clickable:hover:not([class*="sla"]) {
	background: #fff;
	filter: brightness(0.95);
	cursor: pointer;
}

.ui.comments.operation-review-layout {
	max-width: none;
	margin-bottom: 2em;
}
.ui.comments.operation-review-layout .comment .metadata,
.comment.operation-reply-history-comment > .content > .metadata {
	margin-left: 0;
	margin-bottom: 1em;
}
.ui.comments.operation-review-layout .comment .author,
.comment.operation-reply-history-comment .author {
	margin-top: 1em;
}
.operation-details .ui.accordion .content {
	margin-left: 1.5em;
}
.operation-details .ui.accordion .list > .item {
	padding: 0.5em 0;
}

.operation-reply-modal,
.operation-reply-modal > .ui.segment {
	padding: 0;
}
.operation-reply-modal > .ui.operation-reply-form {
	margin-top: 3.2rem;
	padding: inherit;
}
.operation-reply-modal > .ui.accordion .ui.segment {
	max-width: unset;
}
.operation-reply-modal > .ui.accordion hr {
	margin-top: 1.2em;
	margin-bottom: 1.2em;
}
.comment.operation-reply-history-comment.agent-reply {
	margin-left: 2.4em;
}

.ui.form .inline.fields .field:last-child {
	padding-right: 0;
}

.ui.form .inline.field > :first-child,
.ui.form .inline.fields .field > :first-child,
.ui.form .field > label {
	margin-bottom: 0.4rem;
}
.ui.form > .field,
.ui.form .inline.fields {
	margin-top: 1.2rem;
}
.ui.form .field,
.ui.form .inline.fields .field {
	display: block;
	align-self: flex-end;
}
.ui.form .inline.field > .ui.checkbox,
.ui.form .inline.fields .field > .ui.checkbox {
	margin-bottom: 0.3rem;
}
.ui.form .field.date-picker-button,
.ui.form .field.search-button {
	height: 2.75rem;
}

.ui.operation-reply-form .disabled.field,
.ui.operation-reply-form .disabled.fields .field,
.ui.operation-reply-form .field :disabled,
.ui.operation-reply-form .ui.dropdown .menu > .disabled.item,
.ui.operation-reply-form .field.disabled > label,
.ui.operation-reply-form .fields.disabled > label,
.ui.operation-reply-form .ui.disabled.dropdown,
.ui.operation-reply-form .ui.disabled.checkbox > * {
	opacity: 1;
	border-color: #0000;
	resize: none;
}
.ui.form .disabled.field .dropdown.icon::before	 {
	content: none;
}
.ui.operation-reply-form .disabled.field input,
.ui.operation-reply-form .disabled.field textarea,
.ui.operation-reply-form .disabled.field .ui.dropdown {
	background-color: #00000008;
}
.ui.secondary.segment.operation-reply-form .disabled.field textarea {
	text-decoration: line-through;
}

.ui.operation-reply-form .inline.fields.response-text {
	flex-direction: column;
	align-items: flex-end;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .field {
	padding-right: 0;
	margin-bottom: 0;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .field > textarea {
	min-height: 8.14286em;
	border-bottom-right-radius: 0;
	margin-bottom: 0;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .field.text-over-limit > textarea {
	border-color: red;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .ui.label.response-text {
	margin-right: auto;
	padding-left: 0;
	background-color: unset;
	border: unset;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .answer-suggestion {
	display: flex;
	width: 100%;
	align-self: flex-start;
	padding: 0;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .answer-suggestion > .ui.button {
	max-width: 20%;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
	margin-left: 0;
	margin-right: 1em;
	padding: 1em 1.2em;
	font-style: italic;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .answer-suggestion > .ui.button:last-of-type {
	margin-right: 0;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .answer-suggestion > .ui.button.more-predef-answers {
	flex: 0;
	padding: 1em 1.2em;
	overflow: unset;
}
.ui.popup.more-suggestion {
	width: 32em;
	max-height: 18em;
	overflow-y: scroll;
	padding: 0;
}
.ui.popup.more-suggestion > .ui.list > .item {
	padding: 1em;
	font-style: italic;
}
.ui.popup.more-suggestion > .ui.list > .item:hover {
	cursor: pointer;
	background-color: #e3e6e8;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .ui.label {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.ui.operation-reply-form .ui.form .inline.fields.response-text > .ui.label.text-over-limit {
	color: red;
}

.ui.operation-reply-form.reassign {
	margin-bottom: 15rem;
}
.ui.operation-reply-form.reassign .field.operation-cases-user-filter {
	width: 100%;
	margin: 0;
}

.ui.operation-reply-form .field.case-sentiment-happy .ui.selection.dropdown > div.text,
.ui.operation-reply-form .ui.selection.dropdown div.item.case-sentiment-happy > span.text {
	color: #7c0;
}
.ui.operation-reply-form .field.case-sentiment-neutral .ui.selection.dropdown > div.text,
.ui.operation-reply-form .ui.selection.dropdown div.item.case-sentiment-neutral > span.text {
	color: #ec0;
}
.ui.operation-reply-form .field.case-sentiment-unhappy .ui.selection.dropdown > div.text,
.ui.operation-reply-form .ui.selection.dropdown div.item.case-sentiment-unhappy > span.text {
	color: #e00;
}

.ui.form.operation-search-form .operation-rating-range-select > .ui.label,
.ui.form .criteria-field > .ui.label {
	background: #0000;
	padding-left: 0;
	padding-right: 0;
	color: rgba(0,0,0,.87);
}
.ui.form.operation-search-form .operation-rating-range-select > div:nth-child(2),
.ui.form .criteria-field > div {
	display: flex;
	align-items: center;
}
.ui.form.operation-search-form .operation-rating-range-select > div:nth-child(2) > span {
	margin: 0 0.6em;
	font-weight: 700;
}

.ui.accordion.criteria-accordion {
	margin-top: 2.4rem;
}
.ui.accordion.criteria-accordion > .content.active {
	margin-top: 2.4rem;
}

.ui.modal > .content.criteria-modal-content > .ui.secondary.segment {
	padding-top: 1.6rem;
}
.ui.modal > .content.criteria-modal-content > .ui.secondary.segment:nth-child(n+2) {
	margin-top: 2rem;
}

.ui.form > .criteria-field {
	margin-left: 0.2rem;
	width: 100%;
}
.ui.form > .criteria-field > .ui.label {
	width: 24ch;
	height: 2.4rem;
	margin-top: 0.2rem;
}
.ui.form > .criteria-field > .ui.label:nth-child(n+3) {
	flex: 0;
}
.ui.form > .criteria-field > .ui.label.time-input-title {
	padding-left: 0.4em;
	font-size: .92857143em;
}
.ui.form > .criteria-field > .ui.label.exclude-label {
	width: 7ch;
    margin-left: 0.2rem;
}
.ui.form > .criteria-field > .field {
	flex: 1;
}
.ui.form > .criteria-field > .field > label {
	margin-right: 0.6rem;
	margin-bottom: 0;
}
.ui.form > .criteria-field > .exclude-label.disabled,
.ui.form > .criteria-field > .ui.label.time-input-title.disabled {
	opacity: 0.22;
}

.ui.form > .criteria-field .ui.input > input {
	text-transform: initial;
}
.ui.form > .criteria-field > .field.field-checkbox {
	flex: 0;
	margin-top: 0.7rem;
	margin-bottom: auto;
}
.ui.form > .criteria-field > .field.field-checkbox:first-child {
	padding-left: 0;
}